import React, {Component} from 'react';
import Form from 'react-bootstrap/Form'
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'
import Modal from 'react-bootstrap/Modal'
import Col from 'react-bootstrap/Col'
import axios from 'axios'
import { loadReCaptcha } from 'react-recaptcha-v3'
import setHours from 'date-fns/setHours'
import setMinutes from 'date-fns/setMinutes'
import DatePicker, { registerLocale } from "react-datepicker";
import pt from "date-fns/locale/pt-BR";
registerLocale("pt-BR", pt);
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment-timezone"
moment.tz.setDefault('Europe/Lisbon');

class Home extends Component {
    constructor(props){
        super(props)
        this.state = {
            nome: "",
            morada: "",
            tipo: 1,
            telefone: "",
            email: "",
            data: null,
            hora: null,
            horaDisabled: true,
            includeTimes: [],
            marca: "",
            modelo: "",
            matricula: "",
            myModalShow: false,
            myModalText: "",
            confirmationModalShow: false,
            confirmationModalText: "",
            loading: false,
            loadingTime: false
        }
    }

    handleChange(e){
        this.setState({[e.target.id]: e.target.value})
    }

    postContent(){
        if (
            this.state.nome != "" &&
            this.state.morada != "" &&
            this.state.tipo != "" &&
            this.state.telefone != "" &&
            this.state.email != "" &&
            this.state.hora != "" &&
            this.state.marca != "" &&
            this.state.modelo != "" &&
            this.state.matricula
        ) {
            let self = this
            self.setState({
                loading: true
            })
            let params = {
                nome: this.state.nome,
                morada: this.state.morada,
                tipo: this.state.tipo,
                telefone: this.state.telefone,
                email: this.state.email,
                data: this.dateToString(this.state.hora),
                marca: this.state.marca,
                modelo: this.state.modelo,
                matricula: this.state.matricula
            }
            axios.post("/", params)
            .then(function (response) {
                let text = ""
                if (response.data.MarcarInspeccao_TYPEResult && response.data.MarcarInspeccao_TYPEResult.JAEXISTE === "true")
                {
                    text +=  "Já tem uma marcação efetuada com os dados indicados em "
                    text += self.dateToHumanString(moment(response.data.MarcarInspeccao_TYPEResult.DATA.replace('T', ' ')).toDate())
                    self.deleteText()
                } else if (response.data.MarcarInspeccao_TYPEResult.JAEXISTE === "false"){
                    text +=  "A sua marcação está confirmada com sucesso para o dia "
                    text += self.dateToHumanString(moment(response.data.MarcarInspeccao_TYPEResult.DATA.replace('T', ' ')).toDate())
                    self.deleteText()
                } else {
                    text +=  "Ocorreu um erro. Tente novamente!"
                }

                self.setState({
                    myModalShow: true,
                    myModalText: text
                })
            })
        } else {
            this.setState({
                myModalShow: true,
                myModalText: "Por favor preencha todos os campos do formulário. Obrigado."
            })
        }
    }

    handleDataChange(date) {
        self=this

        self.setState({
          horaDisabled: true,
          hora: null,
          includeTimes: [],
          loadingTime: true
        })

        self.setState({
          data: date
          }, function() {
              axios.post("/api/getAvaliableHoursByDay", {
                  data: (this.dateToString(date))
              }).then(function (response) {
                  if (response.data.TYPE_DISPONIBILIDADE_DE_X_A_Y.length === 0) {
                      self.setState({
                          myModalShow: true,
                          myModalText: "Não existem vagas disponíveis para a data escolhida.",
                          loadingTime: false
                      })
                  } else {

                      let filteredHours = response.data.TYPE_DISPONIBILIDADE_DE_X_A_Y.filter((e) => {
                          return e.VAGAS > 0 && moment(e.HORAS.replace('T', ' ')).toDate() > moment().toDate()
                      })
                      let avaliableHours = filteredHours.map((e) => {
                          return moment(e.HORAS.replace('T', ' ')).toDate()
                      })

                      if (avaliableHours.length === 0) {
                          self.setState({
                              myModalShow: true,
                              myModalText: "Não existem vagas disponíveis para a data escolhida.",
                              loadingTime: false
                          })
                      } else {
                          self.setState({
                              horaDisabled: false,
                              hora: avaliableHours[0],
                              includeTimes: avaliableHours,
                              loadingTime: false
                          })
                      }
                    }
                })
            }
        )
    }

    handleHoraChange(time) {
        this.setState({
          hora: time
        })
    }

    dateToString(date) {
      return date.getFullYear() +
        '-' + this.pad(date.getMonth() + 1) +
        '-' + this.pad(date.getDate()) +
        'T' + this.pad(date.getHours()) +
        ':' + this.pad(date.getMinutes()) +
        ':' + this.pad(date.getSeconds()) +
        '.' + ((date.getMilliseconds() / 1000).toFixed(3).slice(2, 5))
    }

    dateToHumanString(date) {
      return  this.pad(date.getDate()) +
      '/' + this.pad(date.getMonth() + 1) +
      '/' + date.getFullYear() +
        ' às ' + this.pad(date.getHours()) +
        ':' + this.pad(date.getMinutes()) +
        ':' + this.pad(date.getSeconds())
    }

    pad(number) {
      if (number < 10) {
        return '0' + number;
      }
      return number;
    }

    handleMyModalClose() {
        this.setState({
            myModalShow: false,
            myModalText: ""
        })
    }

    deleteText(){
        this.setState({
            nome: "",
            morada: "",
            tipo: 1,
            telefone: "",
            email: "",
            data: null,
            hora: null,
            horaDisabled: true,
            includeTimes: [],
            marca: "",
            modelo: "",
            matricula: "",
            loading: false,
            loadingTime: false
        })
    }

    render() {
        return (
            <Container>
                {this.state.myModalShow &&
                    <Modal show={this.state.myModalShow} onHide={this.handleMyModalClose.bind(this)}>
                      <Modal.Header closeButton>
                        <Modal.Title>CITV Macedo</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>{this.state.myModalText}</Modal.Body>
                      <Modal.Footer>
                        <Button variant="primary" onClick={this.handleMyModalClose.bind(this)}>
                          Fechar
                        </Button>
                      </Modal.Footer>
                    </Modal>
                }
                {this.state.loading &&
                    <div className="text-center"><Spinner animation="border" /></div>
                }
                {!this.state.loading &&
                    <Form>
                        <b>1. DATA DE PREFERÊNCIA</b>
                        <br /><br />
                        <Form.Row>
                            <Form.Group as={Col} xs={12} md={4}>
                                <Form.Label>Data <span style={{color:'red'}}>*</span></Form.Label>
                                <div>
                                    <DatePicker
                                        id="data"
                                        selected={this.state.data}
                                        onChange={this.handleDataChange.bind(this)}
                                        minDate={moment().toDate()}
                                        dateFormat="dd/MM/yyyy"
                                        locale={pt}
                                        withPortal
                                    />
                                </div>
                            </Form.Group>
                            <Form.Group as={Col} xs={12} md={4}>
                                <Form.Label>Hora <span style={{color:'red'}}>*</span>
                                    {this.state.loadingTime &&
                                    <div className="text-center"><Spinner animation="border" /></div>
                                }</Form.Label>
                                <div>
                                    {!this.state.loadingTime &&
                                        <DatePicker
                                            disabled={this.state.horaDisabled}
                                            id="hora"
                                            selected={this.state.hora}
                                            onChange={this.handleHoraChange.bind(this)}
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeCaption="Hora"
                                            timeFormat="HH:mm"
                                            dateFormat="dd/MM/yyyy HH:mm"
                                            includeTimes={this.state.includeTimes}
                                            timeIntervals={15}
                                            locale={pt}
                                            withPortal
                                        />
                                    }
                                </div>
                            </Form.Group>
                            <Form.Group as={Col} xs={12} md={4}>
                                <Form.Label>Tipo <span style={{color:'red'}}>*</span></Form.Label>
                                <div>
                                    <Form.Control as="select" id="tipo" value={this.state.tipo} onChange={this.handleChange.bind(this)}>
                                        <option value="1">Inspeção Periódica</option>
                                        <option value="2">Reinspeção</option>
                                        <option value="3">Atribuição Matrícula Nacional</option>
                                        <option value="4">Reposição Matrícula Nacional</option>
                                        <option value="5">Atribuição de Matricula Nacional com CO2</option>
                                        <option value="6">Extraordinária Acidente</option>
                                        <option value="7">Extraordinária Transporte Crianças</option>
                                        <option value="8">Extraordinária Adaptação GPL</option>
                                        <option value="9">Extraordinária Fixação Peliculas</option>
                                        <option value="10">Extraordinária Classificação Portagens</option>
                                        <option value="11">Extraordinária Condições Segurança</option>
                                        <option value="12">Extraordinária Condições Identificação</option>
                                        <option value="13">Extraordinária Transformação Mercadorias/Passageiros</option>
                                        <option value="14">Extraordinária Substituição Motor</option>
                                        <option value="15">Inspeção Facultativa</option>
                                        <option value="16">Outras Inspeções Determinadas pela IMT</option>
                                    </Form.Control>
                                </div>
                            </Form.Group>
                        </Form.Row>
                        <br /><br />
                        <b>2. DADOS DO VEÍCULO</b>
                        <br /><br />
                        <Form.Row>
                            <Form.Group as={Col} xs={12} md={4}>
                                <Form.Label>Matricula <span style={{color:'red'}}>*</span></Form.Label>
                                <Form.Control id="matricula" value={this.state.matricula} onChange={this.handleChange.bind(this)} type="text" placeholder="Introduza a matricula do veiculo" />
                            </Form.Group>
                            <Form.Group as={Col} xs={12} md={4}>
                                <Form.Label>Marca <span style={{color:'red'}}>*</span></Form.Label>
                                <Form.Control id="marca" value={this.state.marca} onChange={this.handleChange.bind(this)} type="text" placeholder="Introduza a marca do veiculo" />
                            </Form.Group>
                            <Form.Group as={Col} xs={12} md={4}>
                                <Form.Label>Modelo <span style={{color:'red'}}>*</span></Form.Label>
                                <Form.Control id="modelo" value={this.state.modelo} onChange={this.handleChange.bind(this)} type="text" placeholder="Introduza o modelo do veiculo" />
                            </Form.Group>
                        </Form.Row>
                        <br /><br />
                        <b>2. DADOS DO CLIENTE</b>
                        <br /><br />
                        <Form.Row>
                            <Form.Group as={Col} xs={12} md={6}>
                                <Form.Label>Nome <span style={{color:'red'}}>*</span></Form.Label>
                                <Form.Control id="nome" value={this.state.nome} onChange={this.handleChange.bind(this)} type="text" placeholder="Introduza o nome completo" />
                            </Form.Group>
                            <Form.Group as={Col} xs={12} md={6}>
                                <Form.Label>Email <span style={{color:'red'}}>*</span></Form.Label>
                                <Form.Control id="email" value={this.state.email} onChange={this.handleChange.bind(this)} type="email" placeholder="Introduza o email" />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} xs={12} md={6}>
                                <Form.Label>Telefone <span style={{color:'red'}}>*</span></Form.Label>
                                <Form.Control id="telefone" value={this.state.telefone} onChange={this.handleChange.bind(this)} type="text" placeholder="Introduza o telefone / telemóvel" />
                            </Form.Group>
                            <Form.Group as={Col} xs={12} md={6}>
                                <Form.Label>Localidade <span style={{color:'red'}}>*</span></Form.Label>
                                <Form.Control id="morada" value={this.state.morada} onChange={this.handleChange.bind(this)} type="text" placeholder="Introduza a localidade" />
                            </Form.Group>
                        </Form.Row>
                        <br /><br />
                        <div align="right">
                            <div style={{float:'left'}}> <span style={{color:'red'}}>*</span> campos obrigatórios</div>
                            <Button variant="primary" type="button" onClick={this.postContent.bind(this)}>
                                Enviar pedido
                            </Button>
                        </div>
                    </Form>
                }
            </Container>
        )
    }
}

export default Home;
